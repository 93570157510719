import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"

import { formatDateToLocalTime } from "../../../helpers/format_date"

//i18n
import { withTranslation } from "react-i18next"

import { parseFromDcu } from "./dcu.service"

import RawPostFormStatus from "./RawPostFormStatus"
import PresetChart from "src/pages/Presets/PresetChart"
import useWindowDimensions from "src/hooks/useWindowDimensions"

import { createAuthHeader } from "../../../helpers/logged_in_user"
import {
    haspermManageDevices,
    haspermDisplayDevices,
    checkPermissions,
} from "../../../helpers/permission_check"
import { useFetchWithAbort } from "src/hooks/useFetchWithAbort"

const heightWithoutPresetGraph = 600

const minPresetHeight = 200

const PostFormStatus = ({ isLoading, error, data, t }: any) => {
    if (!!data && !isLoading && !error) {
        const lastDeviceUpdateTime =
            formatDateToLocalTime(data?.meta?.dcu?.lastDeviceUpdateTime) ??
            t("Not set")
        const lastChangeRequestTime =
            formatDateToLocalTime(data?.meta?.dcu?.lastChangeRequestTime) ??
            t("Not set")
        return (
            <RawPostFormStatus
                t={t}
                isError={false}
                isLoading={false}
                lastDeviceUpdateTimeMsg={lastDeviceUpdateTime}
                lastChangeRequestTimeMsg={lastChangeRequestTime}
            />
        )
    }
    if (isLoading) {
        return (
            <RawPostFormStatus
                t={t}
                isError={false}
                isLoading={true}
                lastDeviceUpdateTimeMsg={""}
                lastChangeRequestTimeMsg={""}
            />
        )
    }
    return (
        <RawPostFormStatus
            t={t}
            isError={true}
            isLoading={false}
            lastDeviceUpdateTimeMsg={t("Server error")}
            lastChangeRequestTimeMsg={t("Server error")}
        />
    )
}

const Dcu = ({ deviceId, t, presets }: any) => {
    const { width, height } = useWindowDimensions()
    const abortControllerRef = useRef<AbortController>()

    const [refreshData, setRefreshData] = useState<boolean>(true)
    const [selectedOption, setSelectedOption] = useState<number | null>(null)
    const [submitCount, setSubmitCount] = useState<any>(null)
    const [isPresetChangeDisabled, setIsPresetChangeDisabled] =
        useState<boolean>(true)

    const [hasPermissionsManage, setHasPermissionsManage] =
        useState<boolean>(false)
    const [hasPermissions, setHasPermissions] = useState<boolean>(false)

    const presetIdToIndex = (presetId: string | number) => {
        const index = presets?.presets.findIndex(
            (item: any) => item.value == presetId
        )
        //console.log("presetIdToIndex", presetId, index, presets)
        return index
    }

    if (deviceId != null) {
        if (!hasPermissionsManage) {
            checkPermissions(haspermManageDevices, setHasPermissionsManage)
        }
        if (!hasPermissions) {
            checkPermissions(haspermDisplayDevices, setHasPermissions)
        }
        const { data, isLoading, error } = useFetchWithAbort(
            `/api/device?data=dcu&id=${deviceId}`,
            {
                depends: [refreshData],
                ...createAuthHeader("displayDevices"),
            },
            abortControllerRef
        )

        const optionToPost = presets?.presets[selectedOption]?.value
        const {
            data: postResult,
            isLoading: isPosting,
            error: errorPost,
        } = useFetchWithAbort(
            `/api/device?data=dcu&id=${deviceId}&selectedOption=${optionToPost}`,
            {
                method: "POST",
                body: JSON.stringify(presets?.dcus[optionToPost]),
                depends: [submitCount],
                ...createAuthHeader("displayDevices"),
            }
        )

        useEffect(() => {
            return () => {
                if (abortControllerRef.current) {
                    abortControllerRef.current.abort()
                }
            }
        }, [])

        useEffect(() => {
            if (!!data && !isLoading && !error) {
                //events setup based on selected preset ...
                setSelectedOption(presetIdToIndex(data?.meta?.dcu?.presetId))
                setRefreshData(false)
            }
        }, [refreshData, deviceId, data, isLoading, error])

        //if deviceId has changed then reload its dcu data!
        useEffect(() => {
            setRefreshData(true)
        }, [deviceId])

        useEffect(() => {
            setSubmitCount(null)
            if (!!postResult && !isPosting && !errorPost) {
                setSelectedOption(
                    presetIdToIndex(postResult?.meta?.dcu?.presetId)
                )
            }
        }, [postResult, data, isPosting, errorPost])

        const submitCurrentEvents = () => {
            setSubmitCount(submitCount + 1)
        }

        const loadInitialEvents = () => {
            setRefreshData(true)
        }

        return (
            <>
                <PostFormStatus
                    isLoading={isPosting || isLoading}
                    error={errorPost}
                    data={postResult || data}
                    t={t}
                />
                {!hasPermissionsManage ? null : (
                    <div className="form-check-right mb-3">
                        <label
                            className="form-label"
                            htmlFor="setPresetChanging"
                        >
                            {t("DevicePresetChangeEnabled")}
                        </label>
                        <input
                            title={t("DevicePresetChangeEnabled")}
                            className="form-check-input"
                            type="checkbox"
                            id="setPresetChanging"
                            defaultChecked={!isPresetChangeDisabled}
                            onChange={() =>
                                setIsPresetChangeDisabled((state) => !state)
                            }
                        />
                    </div>
                )}
                <Select
                    value={presets?.presets[selectedOption]}
                    onChange={(e: any) => {
                        setSelectedOption(presetIdToIndex(e.value))
                    }}
                    options={presets?.presets}
                    classNamePrefix="select2-selection"
                    isDisabled={isPresetChangeDisabled}
                />
                <PresetChart
                    t={t}
                    height={Math.max(
                        minPresetHeight,
                        height - heightWithoutPresetGraph
                    )}
                    data={parseFromDcu(presets?.dcus[selectedOption])}
                />
                <hr />
                {!hasPermissionsManage ? null : (
                    <div className="text-end">
                        <button
                            onClick={loadInitialEvents}
                            type="button"
                            className="btn btn-light me-2"
                        >
                            {t("Reload")}
                        </button>
                        {!isPresetChangeDisabled && (
                            <button
                                onClick={submitCurrentEvents}
                                type="button"
                                className="btn btn-success save-daily"
                            >
                                {t("Submit")}
                            </button>
                        )}
                    </div>
                )}
            </>
        )
    }

    return <h4 className="card-title">{t("NoDeviceInfo")}</h4>
}

export default withTranslation()(Dcu)
